<template>
  <div class="container">
        <div class="top">
            <Form
                :inline="true"
                class="form"
            >
                    <FormItem>
                        <Cascader :load-data="loadData" change-on-select style=" width: 200px" :data="CascaderList" v-model="CascaderId" placeholder="请选择区/街道/社区"></Cascader>
                    </FormItem>
                    <FormItem class="item">
                        <Input
                            :maxlength="20"
                            v-model.trim="searchForm.userName"
                            placeholder="请输入姓名"
                            style="width: 150px"
                        ></Input>
                    </FormItem>
                    <FormItem class="item">
                       <Input
                            :maxlength="20"
                            v-model.trim="searchForm.mobile"
                            placeholder="请输入手机号"
                            style="width: 150px"
                        ></Input>
                    </FormItem>
                    <FormItem>
                        <Select style="width:200px" clearable v-model="searchForm.smsStatus" placeholder="请选择短信状态">
                            <Option value="">全部</Option>
                            <Option value="1">系统已发送</Option>
                            <Option value="2">短信网关已发送</Option>
                            <Option value="3">用户已接收短信</Option>
                            <Option value="4">用户接收失败</Option>
                        </Select>
                    </FormItem>
                    <Button
                        type="primary"
                        icon="ios-search-outline"
                        @click="searchBtn"
                        style="margin-right: 10px;margin-bottom:10px"
                        >查询</Button
                        >
                    <Button type="success" @click="reset" icon="ios-refresh" style="margin-bottom:10px">重置</Button>
            </Form>
        </div>
        <div class="table">
            <Table :loading="loading" :columns="talbeColumns" :data="tableData"  height="660" border stripe></Table>
            <Page
                    style="float: right; margin: 10px"
                    @on-change="morePageFn"
                    :current="page"
                    @on-page-size-change="PageSizeChange"
                    :page-size="pageSize"
                    :total="total"
                    :page-size-opts="[20, 50, 100, 200]"
                    size="small"
                    show-total
                    show-elevator
                    show-sizer
                />
        </div>
      </div>
</template>

<script>
export default {
    props:{
        infoId:{
            type:String,
            default:()=>{
                return ''
            }
        },
        isShow:{
            type:Number,
            default:()=>{
                return 0 
            }
        }
    },
    data(){
        return{
            searchForm:{
                userName:'',
                mobile:'',
                smsStatus:''
            },
            talbeColumns:[
                {
                    title: "区",
                    key: "zoneName",
                    minWidth: 100,
                    align: "center",
                },
                 {
                    title: "街道",
                    key: "streetName",
                    minWidth: 100,
                    align: "center",
                },
               {
                    title: "社区",
                    key: "communityName",
                    minWidth: 100,
                    align: "center",
                },
                 {
                    title: "姓名",
                    key: "userName",
                    minWidth: 100,
                    align: "center",
                },
                 {
                    title: "联系电话",
                    key: "mobile",
                    minWidth: 100,
                    align: "center",
                },
                 {
                    title: "现居地址",
                    key: "addr",
                    minWidth: 100,
                    align: "center",
                },
                 {
                    title: "发送时间",
                    minWidth: 100,
                    align: "center",
                    render:(h,params)=>{
                        return h('div',{},params.row.deliveryTime ? this.$core.formatDate(
                                        new Date(params.row.deliveryTime),
                                        "yyyy-MM-dd hh:mm:ss"
                                      ):'')
                    }
                },
                 {
                    title: "发送结果",
                    key: "smsMsg",
                    minWidth: 100,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},params.row.smsStatus ? (params.row.smsStatus == 1 ? '系统已发送' : params.row.smsStatus == 2 ? '短信网关已发送':params.row.smsStatus == 3 ?'用户已接收短信':params.row.smsStatus == 4 ?'用户接收失败':''):'')
                    }
                },
            ],
            tableData:[],
            page:1,
            pageSize:20,
            total:0,
            loading:false,
             CascaderList:[],
             CascaderId:[],
        }
    },
    created(){
        this.getCascader()
        
    },
    methods:{
        // 异步加载级联
    loadData (item, callback) {
      item.loading = true
      let params = {
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:item.orgCode,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      };
       this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        ...params
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           let arr = this.forMatterTree(res.dataList)
           arr.map(items =>{
             if(items.orgLevel == 4){
               items.loading = false
             }
           })
           item.loading = false
           item.children = arr
           callback(item.children);
         }else{
           callback([]);
         }
      })
    },
    // 点击搜索按钮
    searchBtn(){
        if(this.CascaderId.length != 0 && this.CascaderId.length != 3){
            this.$Message.warning({
                content:'请选择到社区进行查询',
                background:true
            })
            return 
         }
         this.getList(this.CascaderId[this.CascaderId.length - 1])
    },
   // 获取级联
    getCascader(){
      this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:'4401',
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           this.CascaderList = this.forMatterTree(res.dataList)
           this.CascaderList.map(item =>{
             item.loading = false
           })
          
         }
      })
    },
    forMatterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].orgName;
        data[i].value = data[i].orgCode;
         if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
              this.forMatterTree(data[i].children);
         }
      }
      return data;
    },
         // 重置按钮
        reset(){
            this.searchForm ={
                userName:'',
                mobile:'',
                smsStatus:''
            }
            this.CascaderId = []
            this.getList()
        },
         morePageFn(val) {
            this.page = val
            this.getList()
        },
        PageSizeChange(val){
            this.pageSize = val
            this.page = 1
            this.getList()
        },
        // 获取分页接口
        getList(data){
            this.loading = true
            this.$get('/syaa/pc/sy/sms/selectDetailPage',{
                page:this.page,
                pageSize:this.pageSize,
                communityCode:data || '',
                infoId:this.infoId,
                mobile:this.searchForm.mobile,
                smsStatus:this.searchForm.smsStatus,
                userName:this.searchForm.userName
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.total = res.maxCount

                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
    },
    watch:{
        isShow:{
            handler(val){
                if(val){
                    this.getList()
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .container{
        .top{
            height: 40px;
            width: 100%;
            line-height: 40px;
            display: flex;
            .form{
                margin-left: auto;
            }
        }
    }
    /deep/.ivu-form-item-content{
        display: flex;
    }
    /deep/.ivu-table-tip{
        overflow-x: hidden !important;
    }

</style>